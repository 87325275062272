import { emailRules } from '@/helpers/rules-validator';

export default class User {
   firstName: string = '';
   lastName: string = '';
   email: string = '';
   phone: string = '';

   get isValid(): boolean {
      return this.firstName && this.lastName && this.email && this.phone && emailRules(this.email);
   }
}
