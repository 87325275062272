
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Product from '@/data/models/product';
import { capitalizeFirstLetter, formatPrice } from '@/helpers/format-string';
import User from '@/data/models/user';
import Button from '@/components/commons/Button.vue';
import CartProducts from '@/components/cart/CartProducts.vue';
import Order from '@/data/models/order';
import Relay from '@/data/models/relay';
import { CartToOrderProductsId } from '@/data/models/types/order-types';
import { emailValidator, requiredValidator } from '@/helpers/rules-validator';
import { getStripe } from '@/plugins/stripe';
import Lottie from '@/components/commons/Lottie.vue';
import { ADD_TO_CART, REMOVE_FROM_CART, SELECT_RELAY, REMOVE_ALL_FROM_CART } from '@/store/mutation-types';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { EventType, trackEvent } from '@/helpers/analytics';

@Component({
   components: { Button, lottie: Lottie, VuePhoneNumberInput, CartProducts }
})
export default class Cart extends Vue {
   userField: User = new User();
   phoneValid: boolean = false;
   phone: string = "";
   conditionsAccepted: boolean = false;
   relays: Relay[] = [];
   selectedRelay: Relay = this.$store.state.selectedRelay;
   isLoading: boolean = false;
   order: Partial<Order> = null;
   submitted: boolean = false;
   extraEnabled: boolean = false;
   extraValue: number = 2.0;

   mounted() {
      this.$service.relays.getAllRelays().then(relays => (this.relays = relays));
   }

   @Watch('selectedRelay', { immediate: true })
   onIsSelectedRelay() {
      if (this.selectedRelay) {
         trackEvent(EventType.CART_SELECT_RELAY, { relay_name: this.selectedRelay.name, relay_id: this.selectedRelay.id });
      }

      this.$store.commit(SELECT_RELAY, this.selectedRelay);
   }

   @Watch('order', { immediate: false })
   onOrderChanged() {
      const orderObject = Order.map(this.order);

      if (orderObject?.stripeSessionNotNull) {
         this.redirectPayment();
      }
   }

   get relaysItem(): { value: Relay; text: string }[] {
      return this.relays.map(relay => {
         return { value: relay, text: relay.name };
      });
   }

   get formValidate(): boolean {
      return this.userField.isValid && this.$store.state.selectedRelay != null && this.conditionsAccepted;
   }

   get phoneInputText() {
      return this.$t('cart.phoneInputText');
   }

   onPhoneUpdated(event: any) {
     this.userField.phone = event.formattedNumber;
     this.phoneValid = event.isValid;
   }

   //#region fields validators
   get emailValidator(): string {
      return emailValidator(this.userField.email, this.$t('validators.empty').toString(), this.$t('validators.email').toString(), this.submitted);
   }

   get nameValidator(): string {
      return this.submitted ? requiredValidator(this.userField.firstName, this.$t('validators.empty').toString()) : '';
   }

   get lastNameValidator(): string {
      return this.submitted ? requiredValidator(this.userField.lastName, this.$t('validators.empty').toString()) : '';
   }

   get cgvValidator(): string {
      return this.submitted && !this.conditionsAccepted ? this.$t('validators.cgv').toString() : '';
   }

   //#endregion

   get extraStyle() {
      if (this.extraEnabled) {
         return 'extra-enabled';
      }

      return 'extra-disabled';
   }

   get totalPrice() {
      let total = this.$store.getters.getCartTotalPrice;

      if (this.extraEnabled) {
         total += this.extraValue;
      }

      return formatPrice(total);
   }

   formatPrice(price: number) {
      return formatPrice(price);
   }

   capitalizeFirstLetter(string: string): string {
      return capitalizeFirstLetter(string);
   }

   removeFromCart(product: Product) {
      this.$store.commit(REMOVE_FROM_CART, product);
      trackEvent(EventType.CART_TAP_LESS_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   removeAllFromCart(product: Product) {
      this.$store.commit(REMOVE_ALL_FROM_CART, product);
      trackEvent(EventType.CART_TAP_DELETE_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   addToCart(product: Product) {
      this.$store.commit(ADD_TO_CART, product);
      trackEvent(EventType.CART_TAP_MORE_PRODUCT, { product_name: product.name, product_id: product.id });
   }

   saveOrder(): void {
      trackEvent(EventType.CART_TAP_PAY);

      this.submitted = true;

      if (!this.formValidate) {
         return;
      }

      this.isLoading = true;

      const passOrder: Order = Order.map({
         clientName: this.userField.firstName,
         clientLastName: this.userField.lastName,
         clientEmail: this.userField.email,
         clientPhone: this.userField.phone,
         relayId: this.$store.state.selectedRelay.id,
         products: CartToOrderProductsId(this.$store.state.cart)
      });

      this.$service.orders
         .createOrder(passOrder)
         .then(result => {
            this.$bind('order', this.$service.orders.orderDoc(result.id));
         })
         .catch(() => {
            this.isLoading = false;
         });
   }

   async redirectPayment(): Promise<void> {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId: this.order.stripeSessionId }).then(() => null);
   }

   getIcon(product: Product) {
      const _product = Product.map(product.id, product);
      const path = _product.getIcon();

      return path;
   }
}
