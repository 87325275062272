import Product from '@/data/models/product';

export type OrderProductsId = { productId: string; quantity: number };

export type Cart = { [id: string]: CartItem };
export type CartItem = { product: Product; total: number };

export const CartToOrderProductsId = (cart: Cart): OrderProductsId[] => {
   return Object.values(cart).map(cartItem => {
      return { productId: cartItem.product.id, quantity: cartItem.total };
   });
};
