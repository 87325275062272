export const emailRules = (email: string): boolean => !!email && /.+@.+/.test(email);

export const phoneRules = (phone: string): boolean => !!phone && /^((\+)33|0|0033)[1-9](\d{2}){4}$/g.test(phone);

export const emailValidator = (email: string, empty: string, error: string, submitted: boolean): string => {
   if (email === '' && submitted) {
      return empty;
   } else if (!emailRules(email) && email !== '') {
      return error;
   } else {
      return '';
   }
};

export const phoneValidator = (phone: string, empty: string, error: string, submitted: boolean): string => {
   if (phone === '' && submitted) {
      return empty;
   } else if (!emailRules(phone) && phone !== '') {
      return error;
   } else {
      return '';
   }
};

export const requiredValidator = (value: string, empty: string): string => {
   return value === '' ? empty : '';
};
